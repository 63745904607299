'use strict';

class ResetPasswordController {
  constructor(Auth, $state, usSpinnerService, $timeout, Cart) {
    this.usSpinnerService = usSpinnerService;
    this.isDisabled = false;
    this.user = {};
    this.errors = {};
    this.submitted = false;

    this.Auth = Auth;
    this.$state = $state;
    $timeout(()=> {
      this.stopSpin('spinner-1');
    })
  }

  submit(form) {
    this.submitted = true;

    if (form.$valid) {
      this.startSpin('spinner-1');
      this.isDisabled = true;
      this.Auth.resetPassword(this.$state.params.token, this.user.password)
        .then((user) => {
          // Password updated, redirect to login
          this.$state.go('login');
        })
        .catch(err => {
          this.errors.other = err.message;
          if(err.meta && err.meta.error_message) {
            this.errors.other = err.meta.error_message;
          }
          this.stopSpin('spinner-1');
          this.isDisabled = false;
        });
    }
  }

  startSpin(spinner) {
    this.usSpinnerService.spin(spinner);
  }

  stopSpin(spinner) {
    this.usSpinnerService.stop(spinner);
  }
}

angular.module('windmanagerApp')
  .controller('ResetPasswordController', ResetPasswordController);
